
.button {
	border: solid 1px map-get( $colours, gold );
	display: inline-block;
	text-transform: uppercase;
	transition: color 0.3s;
	position: relative;

	span {
		display: block;
		position: relative;
		padding: 1rem 1.5rem;
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: map-get( $colours, gold );
		transform: scale(0);
		transition: transform 0.3s, opacity 0.3s;
		opacity: 0;
	}

	&:hover {
		color: map-get( $colours, black );

		&:before {
			transform: scale(1);
			opacity: 1;
		}
	}

	&:disabled {
		opacity: 0.4;
		background-color: transparent;
		color: map-get( $colours, gold );
		cursor: default;
	}
}

.button--full {
	width: 100%;
}