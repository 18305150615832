
*, *:before, *:after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: center center no-repeat;
    background-size: cover;
}



html {
	background: map-get( $colours, black );
	color: map-get( $colours, gold );
    @extend %fonts_proxima;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.4;
    font-size: 12px;
    min-width: 320px;


    @media( min-width: map-get( $layouts, mid ) ) {
        font-size: 13px;
    }
}

body {
    visibility: hidden;

    &.is-locked {
        position: fixed;
        width: 100%;
        height: 100%;
    }

    .wf-active &, .wf-inactive & {
        visibility: visible;
        animation: fadeIn 0.5s;
    }

    .no-js & {
        visibility: visible;
    }

}

button {
    border: none;
    background: transparent;
    font: inherit;
    text-transform: inherit;
    padding: 0;
    outline: none;
    cursor: pointer;
    color: inherit;
}

a {
    text-decoration: none;
    color: inherit;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

i {
    display: inline-block;
    vertical-align: middle;
}

pre {
    text-align: left;
    text-transform: none;
}

input {
    border-radius: 0;
    appearance: none;
}







