
.projects_page {
	@extend %u-clearfix;

	&.is-hidden {
		display: none;
	}

	&.is-loading {
		perspective: 1000px;
		transform-style: preserve-3d;
	}
}

.projects_page_inner {

	.projects_page.is-loading & {
		animation: flipDown 0.5s;
	}

}

.projects_item--half {
	@media( min-width: map-get( $layouts, mid ) ) {
		width: 50%;
		float: left;
	}
	@media( min-width: map-get( $layouts, large ) ) {
		width: 1/3 * 100%;
	}
}

.projects_item_video {
	background: map-get( $colours, black );
	height: 0;
	padding-bottom: 56.25%;
	position: relative;

	&.is-open {
		z-index: map-get( $layers, overlayContent );
	}
}

.projects_item_video--playIcon {
	&:after {
		@extend %icon;
		content: icon-char(play);
		position: absolute;
		top: 50%;
		left: 50%;
		color: map-get( $colours, white );
		font-size: 6rem;
		margin-left: -3rem;
		margin-top: -3rem;
	}

	&.is-playing:after {
		display: none;
	}
}

.projects_item_thumb {
	width: 100%;
	height: 100%;
	position: absolute;
	cursor: pointer;

	.projects_item_video.is-playing & {
		display: none;
	}

	@media( max-width: map-get( $layouts, mid ) ) {
		display: none !important;
	}
}

.projects_item_player {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.projects_item_video iframe {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	@media( max-width: map-get( $layouts, mid ) ) {
		display: block !important;
	}
}

.projects_item_video.is-playing iframe {
	display: block;
}

.projects_item_text {
	height: 12rem;
	padding: 3rem;
}


.projects_footer {
	margin-top: 3rem;
}

.projects_overlay {
	background: rgba( map-get( $colours, black ), 0.8 );
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: map-get( $layers, overlay );
	display: none;

	&.is-visible {
		animation: fadeIn 0.5s;
		display: block;
	}
}

.projects_close {
	padding: 3rem 0;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 8rem;
	transform: translateY( -8rem );
	transition: transform 0.3s;
	z-index: map-get( $layers, overlayContent );

	&.is-visible {
		transform: translateY( 0 );
	}
}
