
.form {
	position: relative;

	&.is-processing {

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.form_body {
			opacity: 0.3;
		}

	}
}

.form_text {
	width: 100%;
	border: solid 1px currentColor;
	padding: 1rem;
	@extend %fonts_proxima_semibold;
	font-size: 1rem;
	margin-top: 1rem;

	&::-webkit-input-placeholder {
	   color: map-get( $colours, black );
	}

	&::-moz-placeholder {
	   color: map-get( $colours, black );  
	}

	&:-ms-input-placeholder {  
	   color: map-get( $colours, black );  
	}

	&:focus {
		outline: none;
		background-color: map-get( $colours, white );
	}
}

.form_hiddenLabel {
	@extend %u-hidden;
}

.form_button {
	margin-top: 1rem;
	width: 100%;
	padding: 1rem;
	background-color: map-get( $colours, black );
	color: map-get( $colours, gold );
	text-align: center;
	@extend %fonts_proxima_semibold;
	text-transform: uppercase;
	font-size: 1.2rem;

	&:hover, &:focus {
		color: map-get( $colours, white );
	}
}

.form_feedback {
	margin-top: 1rem;
	display: none;
}