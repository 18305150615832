
.page {
	position: relative;
	z-index: map-get( $layers, page );
	transition: transform 0.5s;
}

.page_content {
	max-width: 1400px;
	margin: auto;
}


