
@font-face {
	font-family: "customIcons";
	src: url('../icons/customIcons.eot');
	src: url('../icons/customIcons.eot?#iefix') format('eot'),
		url('../icons/customIcons.woff') format('woff'),
		url('../icons/customIcons.ttf') format('truetype'),
		url('../icons/customIcons.svg#customIcons') format('svg');
}

%icon {
	font-family: "customIcons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	text-decoration: none;
	text-transform: none;
}

@function icon-char($filename) {
	$char: "";

	@if $filename == arrow-down {
		$char: "\EA01";
	}
	@if $filename == arrow-left {
		$char: "\EA02";
	}
	@if $filename == arrow-right {
		$char: "\EA03";
	}
	@if $filename == arrow-up {
		$char: "\EA04";
	}
	@if $filename == facebook {
		$char: "\EA05";
	}
	@if $filename == hexagon {
		$char: "\EA06";
	}
	@if $filename == linkedin {
		$char: "\EA07";
	}
	@if $filename == pause {
		$char: "\EA08";
	}
	@if $filename == play {
		$char: "\EA09";
	}
	@if $filename == shield {
		$char: "\EA0A";
	}
	@if $filename == twitter {
		$char: "\EA0B";
	}

	@return $char;
}

@mixin icon($filename, $insert: before) {
	&:#{$insert} {
		@extend %icon;
		content: icon-char($filename);
	}
}

.icons_arrow-down {
	@include icon(arrow-down);
}
.icons_arrow-left {
	@include icon(arrow-left);
}
.icons_arrow-right {
	@include icon(arrow-right);
}
.icons_arrow-up {
	@include icon(arrow-up);
}
.icons_facebook {
	@include icon(facebook);
}
.icons_hexagon {
	@include icon(hexagon);
}
.icons_linkedin {
	@include icon(linkedin);
}
.icons_pause {
	@include icon(pause);
}
.icons_play {
	@include icon(play);
}
.icons_shield {
	@include icon(shield);
}
.icons_twitter {
	@include icon(twitter);
}