
.t-heading {
	@extend %fonts_proxima_semibold;
	font-size: 1.5rem;
	line-height: 1.2;
	color: map-get( $colours, gold );
	margin-bottom: 0.5rem;
}


.t-copy {

	> *:first-child {
		margin-top: 0;
	}

	> *:last-child {
		margin-bottom: 0;
	}

	p {
		margin: 1rem 0;
	}

	h2 {
		@extend .t-heading;
		margin: 3rem 0 2rem 0;
	}

	a {
		@extend %fonts_proxima_semibold;
		color: map-get( $colours, white );
	}


	img {
		margin: 2rem auto;
	}


}

.t-bold {
	@extend %fonts_proxima_semibold;
}