
.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	min-width: 320px;
	height: 6rem;
	z-index: map-get( $layers, header );
	padding: 2rem;
	background: linear-gradient( to bottom, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.8) 30%, rgba(0,0,0,0) 100% );
	transition: transform 0.3s;

	&.is-hidden {
		transform: translateY( -6rem );
	}
}

.header_nav {
	text-align: center;
}

.header_navItem {
	display: inline-block;
	position: relative;
	height: 2rem;
	
	&:before, &:after {
		content: "";
		display: block;
		width: 100%;
		height: 1px;
		position: absolute;
		left: 0;
		background-color: map-get( $colours, gold );
		opacity: 0;
		transition: opacity 0.3s, transform 0.3s;
	}

	&:before {
		top: 0;
		transform: translateX( -10px );
	}

	&:after {
		bottom: 0;
		transform: translateX( 10px )
	}

	&:hover {

		&:before, &:after {
			opacity: 1;
			transform: translateX(0);
		}

		.header_navItem_text:before, .header_navItem_text:after {
			opacity: 1;
			transform: translateY(0);
		}

	}

}

.header_navItem_text {
	padding: 0 1rem;
	line-height: 2rem;

	&:before, &:after {
		content: "";
		display: block;
		width: 1px;
		height: 100%;
		position: absolute;
		top: 0;
		background-color: map-get( $colours, gold );
		cursor: default;
		opacity: 0;
		transition: opacity 0.3s, transform 0.3s;
	}

	&:before {
		left: 0;
		transform: translateY( 10px );
	}

	&:after {
		right: 0;
		transform: translateY( -10px );
	}
}


.header_audioControl {
	height: 1.5rem;
	position: absolute;
	right: 1rem;
	top: 1rem;
	overflow: hidden;
	cursor: pointer;
	display: none;
	opacity: 0.7;

	div {
		width: 7px;
		height: 100%;
		float: left;
		border: solid 1px map-get( $colours, gold );
		border-bottom: none;
		margin-left: -1px;
	}

	div:nth-child(1) {
		margin-left: 0;
	}

	div:nth-child(2) {
		transform: translateY(0.3rem);
	}

	div:nth-child(3) {
		transform: translateY(0.6rem);
	}

	div:nth-child(4) {
		transform: translateY(0.9rem);
	}

	div:nth-child(5) {
		transform: translateY(1.2rem);
	}

	&.is-visible {
		display: block;
		animation: fadeIn 1s;
	}

	&.is-playing {
		opacity: 1;

		.cssanimations & {

			div {
				transform: translateY(1.4rem);
			}

			div:nth-child(1) {
				animation: audioControl1 2s 0s infinite;
			}

			div:nth-child(2) {
				animation: audioControl1 2s 0.2s infinite;
			}

			div:nth-child(3) {
				animation: audioControl1 2s 0.4s infinite;
			}

			div:nth-child(4) {
				animation: audioControl1 2s 0.6s infinite;
			}

			div:nth-child(5) {
				animation: audioControl1 2s 0.8s infinite;
			}
		
		}

	}

}


@media( min-width: map-get( $layouts, mid ) ) {

	.header {
		height: 8rem;
		padding: 3rem;

		&.is-hidden {
			transform: translateY( -8rem );
		}
	}

}



























