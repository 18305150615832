
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes flipDown {
	0% {
		transform: rotateX(-90deg);
		transform-origin: top;
	}
	100% {
		transform: rotateX(0deg);
		transform-origin: top;
	}
}

@keyframes flipUp {
	0% {
		transform: perspective(600) rotateX(90deg);
	}
	100% {
		transform: rotateX(0);
	}
}

@keyframes audioControl1 {
	0% {
		transform: translateY(1.4rem);
	}
	50% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(1.4rem);
	}
}

@keyframes audioControl2 {
	0% {
		transform: translateY(0.4rem);
	}
	33% {
		transform: translateY(1.6rem);
	}
	66% {
		transform: translateY(0);
	}
}