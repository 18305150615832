
.tracks {
	padding-bottom: 10rem;

	> *:not(:first-child) {
		margin-top: 5rem;
	}
}

.tracks_filters {
	margin-top: 4rem;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;

	> *:not(:first-child) {
		margin: 1rem 0 0 0;
	}
}

.tracks_filters_search {
	border: none;
	color: map-get( $colours, black );
	background-color: map-get( $colours, gold );
	padding: 0 1rem;
	@extend %fonts_proxima_semibold;
	font-size: 1.5rem;
	outline: none;
	width: 100%;
	height: 4rem;
	text-transform: uppercase;

	&::placeholder {
		color: map-get( $colours, black );
	}

	&:focus {
		background-color: map-get( $colours, white );
	}
}

.tracks_filters_types {
	width: 100%;
	height: 4rem;
	position: relative;
	text-align: left;

	select {
		display: none;
	}

	.custom-select-opener {
		display: flex;
		position: absolute;
		height: 100%;
		width: 100%;
		padding: 0 1rem;
		outline: none;
		cursor: pointer;
		align-items: center;
		@extend %fonts_proxima_semibold;
		font-size: 1.5rem;
		background-color: map-get( $colours, gold );
		color: map-get( $colours, black );

		&:focus {
			background-color: map-get( $colours, white );
		}

		&:after {
			display: block;
			@extend %icon;
			content: icon-char(arrow-down);
			font-size: 1rem;
			position: absolute;
			right: 0.75rem;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.custom-select-panel {
		display: none;
		position: absolute;
		top: 100%;
		width: 100%;
		left: 0;
		background-color: map-get( $colours, white );
		color: map-get( $colours, black );
		z-index: 100;
	}

	.custom-select-option {
		padding: 1rem;
		cursor: pointer;

		&.has-focus {
			background-color: map-get( $colours, gold );
		}

	}

	.custom-select-container.is-open {

		.custom-select-opener {
			background-color: map-get( $colours, white );
			color: map-get( $colours, black );
		}

		.custom-select-panel {
			display: block;
		}

	}

}

.tracks_list {
	display: flex;
	width: 100%;
	flex-direction: column;
	border-top: solid 1px rgba( map-get( $colours, gold ), 0.5 );
	border-bottom: solid 1px rgba( map-get( $colours, gold ), 0.5 );

	> *:not(:first-child) {
		border-top: solid 1px rgba( map-get( $colours, gold ), 0.5 );
	}
}

.tracks_item {
	padding: 2rem 0;
	text-align: left;
	position: relative;
}




.tracks_info_primary {
	margin-bottom: 1.5rem;
}


.tracks_info_primary_inner {
	position: relative;
	padding-left: 6.5rem;
	min-height: 5rem;
}

.tracks_item_play {
	font-size: 5rem;
	line-height: 1;
	position: absolute;
	top: 0;
	left: 0;

	&:hover {
		color: white;
	}

	&:after {
		display: block;
		@extend %icon;
		content: icon-char(play);
	}
}

.tracks_info_secondary {
	transition: max-height 0.2s;
	max-height: 30rem;
}

.tracks_progress {
	display: block;
	width: 100%;
	height: 3.5rem;
	position: relative;
}

.tracks_progress_bar {
	position: relative;
	width: 100%;
	height: 1.5rem;
	border: solid 1px map-get( $colours, gold );
}

.tracks_progress_completed {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	background-color: map-get( $colours, gold );
}

.tracks_progress_countDown {
	position: absolute;
	bottom: 0;
	right: 0;
}

.tracks_progress_countUp {
	position: absolute;
	bottom: 0;
	left: 0;
}

.tracks_actions {
	margin-top: 1rem;
}

.tracks_enquire {
	display: block;
	width: 100%;
	padding: 1.3rem;
	background-color: map-get( $colours, gold );
	color: map-get( $colours, black );
	text-align: center;

	&:hover {
		background-color: map-get( $colours, white );
	}
}

.tracks_item_description {
	margin-bottom: 3rem;
}



.tracks_item:not(.is-active) {

	.tracks_info_secondary {
		max-height: 0;
		overflow: hidden;
	}

}

.tracks_item.is-playing {
	.tracks_item_play:after {
		content: icon-char(pause);
	}
}




@media( min-width: map-get( $layouts, mid ) ) {

	.tracks_filters {
		margin-top: 4rem;
		flex-direction: row;

		> *:not(:first-child) {
			margin: 0 0 0 1rem;
		}
	}


}


@media( min-width: map-get( $layouts, large ) ) {

	.tracks_item {
		overflow: hidden;
		transition: padding 0.2s;
	}

	.tracks_info {
		@include grid-gallery( 2, 2rem );
	}

	.tracks_info_primary {
		margin-bottom: 0;
	}

	.tracks_info_secondary {
		display: flex !important;
		max-height: none !important;
		justify-content: space-between;
	}

	.tracks_item_description {
		margin-bottom: 0;
	}

	.tracks_actions {
		width: 11rem;
		padding-left: 2rem;
		flex-shrink: 0;
		margin-top: 0;
	}

	.tracks_progress {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		transition: transform 0.2s;
	}

	.tracks_item.is-active {
		padding-bottom: 8rem;

		.tracks_progress {
			transform: translateY(-5rem);
		}

	}

	.tracks_enquire {
		padding: 0.75rem;
	}

}
