
.modal {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	max-width: 50rem;
	margin: 0 auto;
	background-color: map-get( $colours, gold );
	color: map-get( $colours, black );
	z-index: map-get( $layers, overlayContent );
	padding: 2.5rem 2.5rem 3.5rem 2.5rem;
	text-align: left;
	display: none;
	transform-origin: bottom;

	&.is-open {
		display: block;
		animation: flipUp 0.5s;
	}
}

.modal_header {
	position: relative;
	min-height: 2rem;
	margin-bottom: 1rem;
	padding-right: 3rem;
}

.modal_close {
	width: 2rem;
	height: 2rem;
	position: absolute;
	top: 0;
	right: 0;

	&:before, &:after {
		content: "";
		height: 100%;
		width: 2px;
		background-color: map-get( $colours, black );
		position: absolute;
		top: 0;
		left: 50%;
		margin-left: -1px;
	}


	&:before {
		transform: rotate( 45deg );
	}

	&:after {
		transform: rotate( -45deg );
	}

	span {
		@extend %u-visuallyhidden;
	}
}


.modal_title {
	@extend %fonts_proxima_semibold;
	font-size: 1.2rem;
}