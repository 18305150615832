
.particles_canvas {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: map-get( $layers, bg );
	opacity: 0;

	&.is-visible {
		animation: fadeIn 5s;
		opacity: 1;
	}
}

.particles_vignette {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: map-get( $layers, bg );
	background: radial-gradient(ellipse at center, rgba( 0, 0, 0, 0 ) 50%, rgba( 0, 0, 0, 1 ) 90%);
}