
$colours: (
	white: #ffffff,
	black: #000000,
	lightGrey: #a4a4a4,
	gold: #dfc37c
);

$layers: (
	bg: 1,
	page: 50,
	header: 100,
	overlay: 200,
	overlayContent: 300
);

$layouts: (
	mid: 600px,
	large: 1200px
);


@import 'inc/reset';
@import 'inc/utils';
@import "inc/grid";
@import 'inc/fonts';
@import 'inc/base';
@import 'inc/layout';
@import 'inc/type';
@import 'inc/animations';
@import 'inc/icons';
@import 'inc/button';

@import 'inc/particles';
@import 'inc/page';
@import 'inc/header';
@import 'inc/intro';
@import 'inc/projects';
@import 'inc/contact';
@import 'inc/modal';
@import 'inc/form';
@import 'inc/pagination';

@import "inc/tracks";
@import "inc/subpageHeader";


.cursor {
	position: fixed;
	top: 0;
	left: 0;
	width: 200px;
	height: 200px;
	border-radius: 50%;
	background: rgba( 255, 0, 0, 0.3 );
	z-index: 9999;
	display: none;
}