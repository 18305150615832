
.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
}

.pagination_button {
	padding: 1rem;

	&.is-inactive {
		opacity: 0.5;
		cursor: default;
	}

	&:not(.is-inactive):hover {
		color: map-get( $colours, white );
	}
}



.pagination_overview {
	margin: 0 1.5rem;
	display: flex;

	> *:not(:first-child):before {
		content: '\00a0/';
		display: inline-block;
	}

	a:hover {
		color: map-get( $colours, white );
	}

	.current {
		color: map-get( $colours, white );
	}
}
