
.contact_social {
	margin-top: 3rem;
}

.contact_social_item {
	display: inline-block;
	font-size: 7rem;
	line-height: 1;
	position: relative;
	&:before {
		display: block;
		@extend %icon;
		content: icon-char(hexagon);
	}
}

.contact_updates {
	margin-top: 2rem;
}

.contact_social_link {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	font-size: 2rem;
	line-height: 7rem;
	text-align: center;
	transition: color 0.3s;

	&:hover {
		color: map-get( $colours, white );
	}
}

.contact_social_alt {
	@extend %u-visuallyhidden;
}

.contact_backToTop {
	margin-top: 5rem;
}

.contact_backToTop_link {
	font-size: 5rem;
	transition: color 0.3s;

	&:hover {
		color: map-get( $colours, white );
	}
}

