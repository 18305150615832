

%u-hidden {
    display: none !important;
    visibility: hidden;
}

%u-visuallyhidden, .u-visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

%u-clearfix {
    &:before, &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

%u-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.u-hexagon {
  position: relative;
  width: 60px; 
  height: 34.64px;
  margin: 17.32px 0;
  border-left: solid 2px map-get($colours, gold);
  border-right: solid 2px map-get($colours, gold);
}

.u-hexagon:before,
.u-hexagon:after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 42.43px;
  height: 42.43px;
  transform: scaleY(0.5774) rotate(-45deg);
  background-color: inherit;
  left: 6.7868px;
}

.u-hexagon:before {
  top: -21.2132px;
  border-top: solid 2.8284px map-get($colours, gold);
  border-right: solid 2.8284px map-get($colours, gold);
}

.u-hexagon:after {
  bottom: -21.2132px;
  border-bottom: solid 2.8284px map-get($colours, gold);
  border-left: solid 2.8284px map-get($colours, gold);
}



















