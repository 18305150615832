
%fonts_raisonne { 
	font-family:"Raisonne Demibold", 'Century Gothic', Verdana, sans-serif; 
	font-size-adjust:0.5; 
	font-weight:600; 
	font-style:normal; 
}

%fonts_apercu { 
	font-family:"Apercu Mono Regular", 'Andale Mono', monospace; 
	font-size-adjust:0.5; 
	font-weight:normal; 
	font-style:normal; 
}




%fonts_proxima_semibold { font-family:"Proxima Nova Semibold", 'Helvetica Neue', Arial, Helvetica, sans-serif; font-weight:600; font-style:normal; }

%fonts_proxima { 
	font-family:"Proxima Nova Regular", 'Helvetica Neue', Arial, Helvetica, sans-serif; 
	font-weight:normal; 
	font-style:normal; 
	font-size-adjust: 0.5;
	letter-spacing: 0.1rem;
}