

.l-bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: opacity 3s;
	z-index: map-get( $layers, bg );
	opacity: 0;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		background: red;
		width: 100%;
		height: 100%;
	}

	&.is-visible {
		animation: fadeIn 5s;
		opacity: 1;
	}
}






.l-section {
	display: table;
	width: 100%;
	height: 100vh;
}

.l-section_inner {
	display: table-cell;
	vertical-align: middle;
	padding: 6rem 0;

	@media( min-width: map-get( $layouts, mid ) ) {
		padding: 8rem 0;
	}
}

.l-section_inner--top {
	vertical-align: top;
}

.l-contained {
	padding: 0 2rem;
	max-width: 60rem;
	margin: auto;
}

.l-padded {
	padding: 0 2rem;
}

.l-fullwidth {
	width: 100%;
}






